import React, { useEffect } from 'react';
import OurWebsiteImg from '../../../../assets/meett.jpg';
import "./ourwebsite.css";
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

function OurWebsite() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []);

    return (
        <>
            <section className="py-16 bg-[#613B19] relative xl:mr-0 lg:mr-5 mr-0">
                <div className="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
                    <div className="w-full justify-start items-center xl:gap-12 gap-10 grid lg:grid-cols-2 grid-cols-1">
                        <div className="w-full flex-col justify-center lg:items-start items-center gap-10 inline-flex">
                            <div className="w-full flex-col justify-center items-start gap-8 flex">
                                <div data-aos="fade-right" className="flex-col justify-start lg:items-start items-center gap-4 flex">
                                    <h6 className="text-gray-400 text-base font-normal leading-relaxed">{t('About Us')}</h6>
                                    <div className="w-full flex-col justify-start lg:items-start items-center gap-3 flex">
                                        <h2 className="text-yellow-300 text-4xl font-bold font-manrope leading-normal lg:text-start text-center">
                                            {t('Our Story of Craftsmanship and Quality')}
                                        </h2>
                                        <p className="text-[#fff] text-base font-normal leading-relaxed lg:text-justify text-center">
                                            {t("Since 1985, located in Ghaziabad, Uttar Pradesh, we've built a legacy in premium handicrafts. Offering a curated range, including coasters, combs, drinking mugs, and jewelry boxes, we blend tradition with global quality. Guided by ethical practices and stringent quality checks, we deliver timeless elegance that resonates with our clients.")}
                                        </p>
                                    </div>
                                </div>
                                <div data-aos="fade-left" className="w-full flex-col justify-center items-start gap-6 flex">
                                    <div className="w-full justify-start items-center gap-8 grid md:grid-cols-2 grid-cols-1">
                                        <div className="w-full h-full p-3.5 rounded-xl border border-gray-200 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                                            <h4 className="text-yellow-300 text-2xl font-bold font-manrope leading-9">{t('39+ Years')}</h4>
                                            <p className="text-white text-base font-normal leading-relaxed">{t('Crafting Timeless Masterpieces Together')}</p>
                                        </div>
                                        <div className="w-full h-full p-3.5 rounded-xl border border-gray-200 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                                            <h4 className="text-yellow-300 text-2xl font-bold font-manrope leading-9">{t('255+ Products')}</h4>
                                            <p className="text-white text-base font-normal leading-relaxed">{t('Excellence and All the Top Quality Products')}</p>
                                        </div>
                                    </div>
                                    <div className="w-full h-full justify-start items-center gap-8 grid md:grid-cols-2 grid-cols-1">
                                        <div className="w-full p-3.5 rounded-xl border border-gray-200 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                                            <h4 className="text-yellow-300 text-2xl font-bold font-manrope leading-9">{t('18+ Countries Buyer')}</h4>
                                            <p className="text-white text-base font-normal leading-relaxed">{t('Our Commitment to Excellence Inspires Trust')}</p>
                                        </div>
                                        <div className="w-full h-full p-3.5 rounded-xl border border-gray-200 hover:border-gray-400 transition-all duration-700 ease-in-out flex-col justify-start items-start gap-2.5 inline-flex">
                                            <h4 className="text-yellow-300 text-2xl font-bold font-manrope leading-9">{t('99% Happy Clients')}</h4>
                                            <p className="text-white text-base font-normal leading-relaxed">{t('Mirrors our Focus on Client Satisfaction.')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button onClick={() => navigate('/about-us')} className="sm:w-fit w-full group px-3.5 py-3 rounded-lg border-[2px] border-white shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] transition-all duration-700 ease-in-out justify-center items-center flex">
                                <span className="px-1.5 text-yellow-300 text-md font-medium leading-6 group-hover:-translate-x-0.5 transition-all  duration-700 ease-in-out">{t('Read More')}
                                </span>
                                <svg className="group-hover:translate-x-0.5 transition-all duration-700 ease-in-out" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18" fill="none">
                                    <path d="M6.75265 4.49658L11.2528 8.99677L6.75 13.4996" stroke="#4F46E5" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                        </div>
                        <div data-aos="fade-up" className="w-full lg:justify-start justify-center items-start flex">
                            <div className="sm:w-[564px] w-full sm:h-[646px] h-full sm:bg-gray-100 rounded-3xl sm:border border-gray-200 relative">
                                <img className="sm:mt-5 sm:ml-5 w-full h-full rounded-3xl object-cover" src={OurWebsiteImg} alt={t('About Us image')} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OurWebsite;
