export const links = [
  {
    name: "Our Products",
    links:"our-products",
    submenu: true,
    sublinks: [
      {
        Head: "guitar components",
        linkH:"/our-products-details?slug=guitar-components",
        sublink: [
          { name: "bone nut", link: "bone-nut" },
          { name: "bone saddle", link: "bone-saddle" },
          { name: "bridge pin & bridge pin blank", link: "bridge-pin" },
          { name: "guitar inlay", link: "guitar-inlay-slides" },
          { name: "guitar inlay bids", link: "guitar-inlay-slides" },
          { name: "guitar slides", link: "guitar-inlay-slides" },
          { name: "horn nut", link: "guitar-horn-nut-saddle-pics" },
          { name: "horn saddle", link: "guitar-horn-nut-saddle-pics" },
          { name: "horn pick", link: "guitar-horn-nut-saddle-pics" },
        ],
      },
      {
        Head: "Knife handles",
        linkH:"/our-products-details?slug=Knife-Handle",
        sublink: [
          { name: "camel bone knife handle ", link: "camel-bone-knife-handle" },
          { name: "water buffalo horn", link: "water-buffalo-horn" },
          { name: "Giraffe Bone Look", link: "giraffe-bone-recone-true-x-grip" },
          { name: "recone true stone", link: "giraffe-bone-recone-true-x-grip" },
          { name: "OX zebu horn handle", link: "zebu-sheep-wood-horn-handle" },
          { name: "sheep horn handle", link: "zebu-sheep-wood-horn-handle" },
          { name: "stabilized wood handle", link: "zebu-sheep-wood-horn-handle" },
          { name: "x-grip handle", link: "giraffe-bone-recone-true-x-grip" },
        ],
      },

      {
        Head: "viking craft",
        linkH:"/our-products-details?slug=knife-handle",
        sublink: [
          { name: "viking comb", link: "viking-combs" },
          { name: "drinking horn", link: "drinking-horn" },
          { name: "bone dice", link: "bone-dice" },
          { name: "bone weaving plate", link: "bone-weaving-plate" },
          { name: "viking cloak pins", link: "viking-cloak-pins" },
          { name: "bone needle", link: "bone-needle" },
        ],
      },
      {
        Head: "native american craft",
        linkH:"/our-products-details?slug=native-american-craft",
        sublink: [
          { name: "Bone Hair Pipe Beads", link: "bone-beads" },
          { name: "Horn Hair Pipe Beads", link: "horn-beads" },
          { name: "Bone Spacer", link: "bone-spacer" },
          { name: "Horn Spacer", link: "horn-spacer" },  
          { name: "Buffalo Teeth", link: "buffalo-teeth" },
        ],
      },
      {
        Head: "teflon bone folder",
        linkH:"/our-products-details?slug=teflon-folder",
        sublink: [
          { name: "bone folder", link: "teflon-bone-folder" },
          { name: "teflon folder", link: "teflon-folder" },
          { name: "horn folder", link: "horn-folder" },
          { name: "wood folder", link: "wood-folder" },
          { name: "agate burnisher", link: "agate-burnisher" },
        ],
      },

      {
        Head: "Cue stick components",
        linkH:"/our-products-details?slug=cue-stick-components",
        sublink: [
          { name: "cue ferrules", link: "cue-ferrules" },
          { name: "joint collor", link: "joint-collor" },
          { name: "butt caps", link: "butt-caps" },
          { name: "inlay slabs", link: "inlay-slabs-cue-components" },
          { name: "rods & dowels", link: "rods-dowels-cue-components" },
        ],
      },
      {
        Head: "beads crafts",
        linkH:"/our-products-details?slug=beads-crafts",
        sublink: [
          { name: "pandent", link: "pandent-beads" },
          { name: "horn beads", link: "horn-beads" },
          { name: "bone beads", link: "bone-beads" },
          { name: "wood beads", link: "/" },
          { name: "glass beads", link: "/" },
        ],
      },
      {
        Head: "pen blank & turning block",
        linkH:"/our-products-details?slug=pen-blank-turning-scale",
        sublink: [
          { name: "bone pen-blank", link: "bone-pen-blank" },
          { name: "Horn pen-blank", link: "horn-pen-blank" },
          { name: "wood pen-blank", link: "wood-pen-blank" },
          { name: "X-Grip pen-blank", link: "x-grip-pen-blank" },
          { name: "Recon/Tru Stone pen-blank", link: "recone-stone-pen-blank" },
        ],
      },
      {
        Head: "",
        sublink: [
          { name: "bone clasps", link: "bone-clasp" },
          { name: "horn cutlery spoon", link: "/" },
          { name: "piono & organ keys", link: "piono-organ-keys" },
          { name: "home decor", link: "home-decor" },
          { name: "bone button", link: "stag-bone-button" },
          { name: "Dominoes Set", link: "dominoes-set" },
          { name: "shoe horn", link: "shoe-horn" },
          { name: "Antique Knife", link: "antique-bone-knife" },
        ],
      },
    ],
  },
];
